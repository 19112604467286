import '../css/ProductList.css';
import React, { useMemo, useState, useEffect } from "react";
import { useTable, usePagination } from "react-table";
import axiosInstance from "../api/axiosConfig";
import dayjs from 'dayjs';

const ProductList = () => {
    localStorage.setItem('header-nav', "Anasayfa - Ürün Malzeme Listesi");

    const [allPrices, setAllPrices] = useState([]);
    const [allData, setAllData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPrices = async () => {
            try {
                const response = await axiosInstance.get('/api/currency/get');
                setAllPrices(response.data.response);
            } catch (error) {
                console.error('Veri alınırken hata oluştu:', error);
            }
        };

        const fetchProducts = async () => {
            try {
                const response = await axiosInstance.get('/api/product/get');
                setAllData(response.data.response);
            } catch (error) {
                console.error('Veri alınırken hata oluştu:', error);
            }
        };

        Promise.all([fetchPrices(), fetchProducts()]).then(() => setLoading(false));
    }, []);

    const handleButtonClick = (product) => {
        console.log("Butona tıklanan kullanıcı:", product);
    };

    const data = useMemo(
        () => allData.map(product => ({
            ...product,
            created_at: dayjs(product.created_at).format('DD.MM.YYYY HH:mm:ss')
        })),
        [allData]
    );

    const columns = useMemo(() => [
        {
            Header: "Fotoğraf",
            Cell: ({ row }) => (
                <img
                    src={
                        process.env.REACT_APP_BACKEND_URL +
                        (row.original.image ? row.original.image : '/uploads/1729066753801-150x150.png')
                    }
                    alt="Fotoğraf"
                    style={{ width: '30px', height: '30px', objectFit: 'cover' }}
                />
            )
        },
        {
            Header: "Ürün Kodu",
            accessor: "product_code"
        },
        {
            Header: "Barkod",
            accessor: "barcode"
        },
        {
            Header: "Marka",
            accessor: "brand"
        },
        {
            Header: "Model",
            accessor: "model"
        },
        {
            Header: "KDV",
            accessor: "kdv"
        },
        {
            Header: "Alış Fiyatı",
            Cell: ({ row }) => {
                const currency = allPrices.find(price => price.id === row.original.purchase_price_currency);
                const currencyName = currency ? currency.name : '';

                return (
                    <span>
                        {row.original.purchase_price} {currencyName}
                    </span>
                );
            }
        },
        {
            Header: "Satış Fiyatı",
            Cell: ({ row }) => {
                const currency = allPrices.find(price => price.id === row.original.sales_price_currency);
                const currencyName = currency ? currency.name : '';

                return (
                    <span>
                        {row.original.sales_price} {currencyName}
                    </span>
                );
            }
        },
        {
            Header: "Eklenme Tarihi",
            accessor: "created_at"
        }
    ], [allPrices]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state,
        setPageSize,
        gotoPage,
        canPreviousPage,
        canNextPage,
        pageCount,
        nextPage,
        previousPage,
        pageOptions
    } = useTable(
        { columns, data },
        usePagination
    );

    if (loading) {
        return <div>Veriler yükleniyor...</div>; // Yüklenme animasyonu gösteriyoruz
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 mb-3">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="app-pagination-category-title d-flex align-items-center">
                            <h4 className="pl-1 mb-3">Ürün Malzeme Listesi</h4>
                            <button className="btn ml-2 btn-sm btn-primary" disabled="true">Filtreleri Uygula</button>
                        </div>
                        <div className="pagination">
                            <span className="mr-2">Toplam {state.pageSize} Kayıt Gösteriliyor</span>
                            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                {'<<'}
                            </button>
                            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                                {'<'}
                            </button>
                            <span>
                                Sayfa{' '}
                                <strong>
                                    {state.pageIndex + 1} / {pageOptions.length}
                                </strong>{' '}
                            </span>
                            <button onClick={() => nextPage()} disabled={!canNextPage}>
                                {'>'}
                            </button>
                            <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                {'>>'}
                            </button>

                            <select
                                value={state.pageSize}
                                onChange={e => setPageSize(Number(e.target.value))}
                            >
                                {[5, 10, 20, 50].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-md-2">
                    <form style={{
                        background: "#fff",
                        padding: "10px",
                        borderRadius: "10px"
                    }}>
                        <h6 className="pl-1 mb-3">Ürün Filtreleme</h6>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group no-border">
                                    <div className="d-flex align-items-center">
                                        <input
                                            type="text"
                                            className="bg-transparent"
                                            placeholder="Ürün Kodu"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group no-border">
                                    <div className="d-flex align-items-center">
                                        <input
                                            type="text"
                                            placeholder="Ürün/Malzeme Tanımı"
                                            className="bg-transparent"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div
                                    className="type-area d-flex align-items-center justify-content-start">
                                    <input className="w-0 mr-2" type="radio" name="product_type"
                                           value="mal"/>
                                    <label className="mb-0">Mallar</label>
                                    <input className="w-0 ml-4 mr-2" type="radio" name="product_type"
                                           value="hizmet"/>
                                    <label className="mb-0">Hizmet</label>
                                </div>
                            </div>

                            <div className="col-12 mt-3">
                                <div className="form-group no-border d-flex align-items-center">
                                    <label className="w-50">Ölçü Birimi</label>
                                    <select
                                        className="w-50 bg-transparent"
                                    >
                                        <option value="">Seçiniz</option>
                                        <option value="1">Birim 1</option>
                                        <option value="2">Birim 2</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="form-group no-border d-flex align-items-center">
                                    <label className="w-50">Mal Grubu</label>
                                    <select
                                        className="w-50 bg-transparent"
                                    >
                                        <option value="">Seçiniz</option>
                                        <option value="1">Grup 1</option>
                                        <option value="2">Grup 2</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="form-group no-border d-flex align-items-center">
                                    <label className="w-75">Parti Takibi</label>
                                    <div className="d-flex align-items-center">
                                        <input
                                            type="checkbox"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="form-group no-border d-flex align-items-center">
                                    <label className="w-75">Seri No Takibi</label>
                                    <div className="d-flex align-items-center">
                                        <input
                                            type="checkbox"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="form-group no-border d-flex align-items-center">
                                    <label className="w-50">Barkod No</label>
                                    <input
                                        className="w-50 bg-transparent"
                                        type="text"
                                        placeholder="****"
                                    />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="form-group no-border d-flex align-items-center">
                                    <label className="w-50">Marka</label>
                                    <input
                                        className="w-50 bg-transparent"
                                        type="text"
                                        placeholder="*********"
                                    />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="form-group no-border d-flex align-items-center">
                                    <label className="w-50">Model</label>
                                    <input
                                        className="w-50 bg-transparent"
                                        type="text"
                                        placeholder="*********"
                                    />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="form-group no-border d-flex align-items-center">
                                    <label className="w-75">Üretici Tanımı</label>
                                    <select
                                        className="w-25 bg-transparent"
                                    >
                                        <option value="">Seçiniz</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-12">
                                <div
                                    className="form-group no-border mb-0 d-flex align-items-center">
                                    <label className="w-50">Alış Fiyatı Aralığı</label>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="row form-group no-border">
                                    <div className="col-4">
                                        <input
                                            type="text"
                                            placeholder="Min. Fiyat"
                                            className=" bg-transparent"
                                        />
                                    </div>
                                    <div className="col-4">
                                        <input
                                            type="text"
                                            placeholder="Max. Fiyat"
                                            className=" bg-transparent"
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <select
                                            className=" bg-transparent ml-2"
                                        >
                                            <option>TRY</option>
                                            <option>EUR</option>
                                            <option>USD</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <div
                                    className="form-group no-border mb-0 d-flex align-items-center">
                                    <label className="w-50">Satış Fiyatı Aralığı</label>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="row form-group no-border">
                                    <div className="col-4">
                                        <input
                                            type="text"
                                            placeholder="Min. Fiyat"
                                            className=" bg-transparent"
                                        />
                                    </div>
                                    <div className="col-4">
                                        <input
                                            type="text"
                                            placeholder="Max. Fiyat"
                                            className=" bg-transparent"
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <select
                                            className=" bg-transparent ml-2"
                                        >
                                            <option>TRY</option>
                                            <option>EUR</option>
                                            <option>USD</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>

                <div className="col-md-10">
                    <div className="table-special">
                        <div className="ts-head">
                            {headerGroups.map(headerGroup => (
                                <span {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <span {...column.getHeaderProps()}>
                                            {column.render("Header")}
                                        </span>
                                    ))}
                                </span>
                            ))}
                        </div>
                        <div className="ts-body">
                            {page.map(row => {
                                prepareRow(row);
                                return (
                                    <span className="ts-item" {...row.getRowProps()} key={row.original.id}>
                                        {row.cells.map(cell => (
                                            <span {...cell.getCellProps()}>
                                                {cell.render("Cell")}
                                            </span>
                                        ))}
                                    </span>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductList;
