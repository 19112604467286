import React, { useEffect, useState } from "react";
import "../css/progressbar.css";

const GlobalLoadingBar = () => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const originalXhr = window.XMLHttpRequest;

        const modifiedXhr = function () {
            const xhr = new originalXhr();

            xhr.addEventListener("loadstart", () => {
                setProgress(0);
            });

            xhr.addEventListener("progress", (event) => {
                if (event.lengthComputable) {
                    const percentComplete = (event.loaded / event.total) * 100;
                    setProgress(percentComplete);
                }
            });

            xhr.addEventListener("loadend", () => {
                setProgress(100);
                setTimeout(() => setProgress(0), 100);
            });

            return xhr;
        };

        window.XMLHttpRequest = modifiedXhr;

        return () => {
            window.XMLHttpRequest = originalXhr;
        };
    }, []);

    return (
        <>
            <span
                className="progressbar"
                style={{
                    width: `${progress}%`,
                }}
            ></span>
        </>
    );
};

export default GlobalLoadingBar;
