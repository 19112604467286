import '../css/EditProduct.css';
import React, {useState, useRef, useEffect, useCallback} from "react";
import "intl-tel-input/styles";
import axiosInstance from "../api/axiosConfig";
import {error, success} from "@pnotify/core";
import {faEye, faTimes, faUpload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const EditProduct = () => {
    const formRef = useRef();

    const [findProduct, setFindProduct] = useState(false);
    const [buttonVisible, setButtonVisible] = useState(false);
    const [theProduct, setTheProduct] = useState([]);
    const [selectFindingProduct, setSelectFindingProduct] = useState("");
    const [finding, setFinding] = useState(false);
    const [products, setProducts] = useState([]);
    const [productCode, setProductCode] = useState("");
    const [productTitle, setProductTitle] = useState("");
    const [measureUnit, setMeasureUnit] = useState("");
    const [productGroup, setProductGroup] = useState("");
    const [manufacturerNo, setManufacturerNo] = useState("");
    const [manufacturerTitle, setManufacturerTitle] = useState("");
    const [barcode, setBarcode] = useState("");
    const [kdv, setKdv] = useState("");
    const [brand, setBrand] = useState("");
    const [model, setModel] = useState("");
    const [partyTracking, setPartyTracking] = useState(false);
    const [serialNoTracking, setSerialNoTracking] = useState(false);
    const [purchasePrice, setPurchasePrice] = useState("");
    const [salesPrice, setSalesPrice] = useState("");
    const [purchasePriceCurrency, setPurchasePriceCurrency] = useState("TRY");
    const [salesPriceCurrency, setSalesPriceCurrency] = useState("TRY");
    const [comment, setComment] = useState("");
    const [image, setImage] = useState(null);

    const handleSuccessNotification = () => {
        success({
            title: 'Başarılı!',
            text: 'Kayıt Başarılı!',
        });
    };

    const handleErrorNotification = (errormessage) => {
        error({
            title: 'Hata!',
            text: errormessage,
        });
    };

    const selectTabs = useCallback((id) => {
        document.querySelectorAll('.the-tabs').forEach(function (element) {
            element.classList.add('d-none');

            if(parseInt(element.getAttribute('data-tabs')) === id) {
                element.classList.remove('d-none');
                document.querySelectorAll('.tabs-btn').forEach(function (el) {
                    el.classList.remove('active');
                })
                document.querySelector(".tabs-btn[data-tabs='"+id+"']").classList.add('active');
            }
        })
    }, []);

    useEffect( (e) => {
        setFinding(true);
        async function fetchData() {
            if(productCode !== '') {
                const response = await axiosInstance.get('/api/product/find?product_code=' + productCode);
                setProducts(response.data.response)
            }else {
                setProducts([])
            }
            setFinding(false);
        }

        fetchData();
        }, [productCode]);

    useEffect( (e) => {
        setFinding(false);
        async function fetchData() {
            if(productCode !== '') {
                const response = await axiosInstance.get('/api/product/take-product?product_code=' + productCode);
                setFindProduct(true)
                setTheProduct(response.data.response);
            }
        }

        fetchData();
    }, [selectFindingProduct]);

    const getProductImage = async (id) => {
        if(id !== '') {
            const response = await axiosInstance.get('/api/product/take-product?product_code=' + id);

            setImage(response.data.response[0].image);
        }
    }

    useEffect( (e) => {
        if(theProduct.length > 0) {
            let product = theProduct[0];

            setProductCode(product.product_code);
            setProductTitle(product.product_title);
            setMeasureUnit(product.measure_unit);
            setProductGroup(product.product_group);
            setManufacturerNo(product.manufacturer_no);
            setManufacturerTitle(product.manufacturer_title);
            setBarcode(product.barcode);
            setKdv(product.kdv);
            setBrand(product.brand);
            setModel(product.model);
            setPartyTracking(product.party_tracking);
            setSerialNoTracking(product.serial_no_tracking);
            setPurchasePrice(product.purchase_price);
            setSalesPrice(product.sales_price);
            setPurchasePriceCurrency(product.purchase_price_currency);
            setSalesPriceCurrency(product.sales_price_currency);
            setComment(product.comment);
            setImage(product.image);

            setButtonVisible(true)
        }
    }, [theProduct]);

    const selectProduct = async (id) => {
        setProductCode(id)
        setSelectFindingProduct(id);
    }

    const removeProduct = async (id) => {
        setProductCode("")
        setSelectFindingProduct("");
        setFindProduct(false);
        setProducts([]);
        setTheProduct([]);
        setButtonVisible(false);
        formRef.current.reset();
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(theProduct.length > 0) {
            try {
                const formData = new FormData();
                formData.append('productCode', productCode);
                formData.append('productTitle', productTitle);
                formData.append('measureUnit', measureUnit);
                formData.append('productGroup', productGroup);
                formData.append('manufacturerNo', 1);
                formData.append('manufacturerTitle', 2);
                formData.append('barcode', barcode);
                formData.append('kdv', kdv);
                formData.append('brand', brand);
                formData.append('model', model);
                formData.append('partyTracking', partyTracking ? 1 : 0);
                formData.append('serialNoTracking', serialNoTracking ? 1 : 0);
                formData.append('purchasePrice', purchasePrice);
                formData.append('salesPrice', salesPrice);
                formData.append('purchasePriceCurrency', purchasePriceCurrency == 'TRY' ? 1 : 2);
                formData.append('salesPriceCurrency', salesPriceCurrency == 'TRY' ? 1 : 2);
                formData.append('comment', comment);

                if (image) {
                    formData.append('image', image);
                }

                const response = await axiosInstance.post('/api/product/update', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                handleSuccessNotification();
                getProductImage(productCode);
            } catch (error) {
                handleErrorNotification(error.response.data.error);
            }
        }
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-8">
                        <div className="col-12">
                            <div className="app-pagination-category-title d-flex align-items-center">
                                <h4 className="pl-1 mb-3">Ürün/Malzeme Düzenle</h4>
                                {
                                    buttonVisible ? (
                                        <>
                                            <button type="submit" className="btn btn-sm btn-primary ml-2"
                                                    onClick={handleSubmit}>Kaydet
                                            </button>
                                        </>
                                    ) : ''
                                }
                            </div>
                        </div>
                        <div className="smart-card">
                            <form ref={formRef} onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6 pt-3">
                                        <div className="form-group no-border pl-1 pr-1">
                                            <input
                                                type="text"
                                                placeholder="Ürün/Malzeme Tanımı"
                                                value={productTitle}
                                                onChange={(e) => setProductTitle(e.target.value)}
                                            />

                                            <div
                                                className="type-area mt-3 d-flex align-items-center justify-content-start">
                                                <input className="w-0 mr-2" type="radio" name="product_type"
                                                       value="mal"/>
                                                <label className="mb-0">Mallar</label>
                                                <input className="w-0 ml-4 mr-2" type="radio" name="product_type"
                                                       value="hizmet"/>
                                                <label className="mb-0">Hizmet</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 pt-3">
                                        <div className="form-group no-border alertt pl-1 pr-1">
                                            <div className="d-flex align-items-center">
                                                <div className="inputcontainer w-100">
                                                    <input
                                                        style={{fontSize:"12px"}}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Ürün Kodu ile Arama Yapın"
                                                        value={productCode}
                                                        onChange={(e) => setProductCode(e.target.value)}
                                                        readOnly={selectFindingProduct === '' ? '' : "readonly"}
                                                    />
                                                    {selectFindingProduct === "" && products.length > 0 ? (
                                                        <>
                                                            <div className="p-dropdown">
                                                                {products.map(e => {
                                                                    return (
                                                                        <>
                                                                            <div className="pd-item"
                                                                                 onClick={() => selectProduct(e.product_code)}>
                                                                                <span>{e.product_code}</span>
                                                                                <spant>{e.brand}</spant>
                                                                                <span>{e.model}</span>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })}
                                                            </div>
                                                        </>
                                                    ) : ""}
                                                    {finding ? (
                                                        <>
                                                            <div className="icon-container">
                                                                <i className="loader-mini"></i>
                                                            </div>
                                                        </>
                                                    ) : ""}
                                                </div>
                                                {selectFindingProduct !== "" ? (
                                                    <>
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger ml-2"
                                                            onClick={removeProduct}
                                                        >
                                                            <FontAwesomeIcon icon={faTimes}/>
                                                        </button>
                                                    </>
                                                ) : ""}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2 d-flex justify-content-end">
                                        <div className="image-area" onClick={() => {
                                            document.querySelector('.file-input').click()
                                        }}>
                                            <img style={{width:"100%"}} src={
                                                process.env.REACT_APP_BACKEND_URL +
                                                (image ? image : '/uploads/1729066753801-150x150.png')
                                            } />
                                        </div>
                                        <input type="file" className="d-none file-input"
                                               onChange={(e) => setImage(e.target.files[0])}/>
                                    </div>
                                    <div className="col-12 mb-3">
                                        <div className="tabs-area">
                                            <button type="button" className="tabs-btn active" data-tabs="0"
                                                    onClick={() => selectTabs(0)}>Ürün Genel Bilgileri
                                            </button>
                                            <button type="button" className="tabs-btn" data-tabs="1"
                                                    onClick={() => selectTabs(1)}>Fiyat Bilgileri
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-12 the-tabs" data-tabs="0">
                                        <div className="row">
                                            <div className="col-md-6 p-0">
                                                <div className="form-group no-border d-flex align-items-center col-12">
                                                    <label className="w-50">Ölçü Birimi</label>
                                                    <select
                                                        value={measureUnit}
                                                        onChange={(e) => setMeasureUnit(e.target.value)}
                                                        className="w-50"
                                                    >
                                                        <option value="">Seçiniz</option>
                                                        <option value="1">Birim 1</option>
                                                        <option value="2">Birim 2</option>
                                                    </select>
                                                </div>
                                                <div className="form-group no-border d-flex align-items-center col-12">
                                                    <label className="w-50">Mal Grubu</label>
                                                    <select
                                                        value={productGroup}
                                                        onChange={(e) => setProductGroup(e.target.value)}
                                                        className="w-50"
                                                    >
                                                        <option value="">Seçiniz</option>
                                                        <option value="1">Grup 1</option>
                                                        <option value="2">Grup 2</option>
                                                    </select>
                                                </div>
                                                <div className="form-group no-border d-flex align-items-center col-12">
                                                    <label className="w-75">Parti Takibi</label>
                                                    <div className="d-flex align-items-center">
                                                        <input
                                                            type="checkbox"
                                                            checked={partyTracking}
                                                            onChange={() => setPartyTracking(!partyTracking)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group no-border d-flex align-items-center col-12">
                                                    <label className="w-75">Seri No Takibi</label>
                                                    <div className="d-flex align-items-center">
                                                        <input
                                                            type="checkbox"
                                                            checked={serialNoTracking}
                                                            onChange={() => setSerialNoTracking(!serialNoTracking)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group no-border d-flex align-items-center col-12">
                                                    <label className="w-50">Barkod No</label>
                                                    <input
                                                        className="w-50"
                                                        type="text"
                                                        placeholder="****"
                                                        value={barcode}
                                                        onChange={(e) => setBarcode(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group no-border d-flex align-items-center col-12">
                                                    <label className="w-50">Marka</label>
                                                    <input
                                                        className="w-50"
                                                        type="text"
                                                        placeholder="*********"
                                                        value={brand}
                                                        onChange={(e) => setBrand(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group no-border d-flex align-items-center col-12">
                                                    <label className="w-50">Model</label>
                                                    <input
                                                        className="w-50"
                                                        type="text"
                                                        placeholder="*********"
                                                        value={model}
                                                        onChange={(e) => setModel(e.target.value)}
                                                    />
                                                </div>

                                                <div className="form-group no-border d-flex align-items-center col-12">
                                                    <label className="w-75">Üretici No</label>
                                                    <select
                                                        className="w-25"
                                                        value={manufacturerNo}
                                                        onChange={(e) => setManufacturerNo(e.target.value)}
                                                    >
                                                        <option value="">Seçiniz</option>
                                                    </select>
                                                </div>
                                                <div className="form-group no-border d-flex align-items-center col-12">
                                                    <label className="w-75">Üretici Tanımı</label>
                                                    <select
                                                        className="w-25"
                                                        value={manufacturerTitle}
                                                        onChange={(e) => setManufacturerTitle(e.target.value)}
                                                    >
                                                        <option value="">Seçiniz</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 p-0 the-tabs d-none" data-tabs="1">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div
                                                        className="form-group no-border d-flex align-items-center col-12">
                                                        <label className="w-50">Alış Fiyatı</label>
                                                        <div className="w-50 d-flex align-items-center">
                                                            <input
                                                                type="text"
                                                                placeholder="Alış Fiyatı"
                                                                className="w-75"
                                                                value={purchasePrice}
                                                                onChange={(e) => setPurchasePrice(e.target.value)}
                                                            />
                                                            <select
                                                                className="w-25 ml-2"
                                                                value={purchasePriceCurrency}
                                                                onChange={(e) => setPurchasePriceCurrency(e.target.value)}
                                                            >
                                                                <option>TRY</option>
                                                                <option>EUR</option>
                                                                <option>USD</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="form-group no-border d-flex align-items-center col-12">
                                                        <label className="w-75">Alış Kdv Oranı</label>
                                                        <select
                                                            value={kdv}
                                                            onChange={(e) => setKdv(e.target.value)}
                                                            className="w-25"
                                                        >
                                                            <option value="">Seçiniz</option>
                                                            <option value="18">18%</option>
                                                            <option value="8">8%</option>
                                                        </select>
                                                    </div>
                                                    <div
                                                        className="form-group no-border d-flex align-items-center col-12">
                                                        <label className="w-50">Satış Fiyatı</label>
                                                        <div className="w-50 d-flex align-items-center">
                                                            <input
                                                                type="text"
                                                                placeholder="Satış Fiyatı"
                                                                className="w-75"
                                                                value={salesPrice}
                                                                onChange={(e) => setSalesPrice(e.target.value)}
                                                            />
                                                            <select
                                                                className="w-25 ml-2"
                                                                value={salesPriceCurrency}
                                                                onChange={(e) => setSalesPriceCurrency(e.target.value)}
                                                            >
                                                                <option>TRY</option>
                                                                <option>EUR</option>
                                                                <option>USD</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="form-group no-border d-flex align-items-center col-12">
                                                        <label className="w-75">Satış Kdv Oranı</label>
                                                        <select
                                                            value={kdv}
                                                            onChange={(e) => setKdv(e.target.value)}
                                                            className="w-25"
                                                        >
                                                            <option value="">Seçiniz</option>
                                                            <option value="18">18%</option>
                                                            <option value="8">8%</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 form-group d-grid">
                                        <label>Genel Açıklama</label>
                                        <textarea
                                            rows={4}
                                            className="product-note"
                                            value={comment}
                                            onChange={(e) => setComment(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="col-12">
                            <div className="app-pagination-category-title d-flex align-items-center">
                                <button type="submit" className="btn btn-sm btn-primary mt-3 mb-1">Etiketleri Yazdır
                                </button>
                                <button type="submit" className="btn btn-sm btn-secondary ml-2 mt-3 mb-1">Takip Et
                                </button>
                                <button type="submit" className="btn btn-sm btn-secondary ml-2 mt-3 mb-1">İşlemler
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditProduct;
