import '../css/MasterDataManagement.css';
import React from "react";
import {Link} from 'react-router-dom';
import {faGear, faBars, faEdit, faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const UserManagement = () => {
    return (
        <>
            <div className="dashboard">
                <div className="container-fluid p-0 m-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="app-pagination-category-title d-block mb-4">
                                <h4 style={{
                                    fontSize: "17px",
                                    paddingLeft: "30px",
                                    marginTop: "20px",
                                    borderLeft: "3px solid #d67940",
                                    marginLeft: "2px"
                                }}>Kullanıcı Yönetimi</h4>
                            </div>
                            <div className="app-pagination p-0">
                                <div className="app-item">
                                    <Link to="/user-requests">
                                        <span>Abonelik Talep Listesi</span>
                                        <FontAwesomeIcon icon={faBars}/>
                                    </Link>
                                </div>
                                <div className="app-item">
                                    <Link to="/users">
                                        <span>Aktif Kullanıcı Listesi</span>
                                        <FontAwesomeIcon icon={faUser}/>
                                    </Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserManagement;