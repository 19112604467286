import React from 'react';

const Page404 = () => {
    return (
        <div className="404" style={{padding: `20px 40px`}}>
            <span>Sayfa Bulunamadı : 404</span>
        </div>
    );
};

export default Page404;