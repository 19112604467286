import '../css/CreateVendor.css';
import React, {useCallback, useEffect, useRef, useState} from "react";
import axiosInstance from "../api/axiosConfig";
import IntlTelInput from 'intl-tel-input/react';
import "intl-tel-input/styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpload} from "@fortawesome/free-solid-svg-icons";
import {error, success} from "@pnotify/core";

const CreateVendor = () => {
    const formRef = useRef();
    const [address, setAddress] = useState('');
    const [cities, setCities] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [neighboor, setNeighboor] = useState([]);
    const [comment, setComment] = useState([]);
    const [phone, setPhone] = useState('');

    const [selectedCity, setSelectedCity] = useState('');
    const [selectedDistricts, setSelectedDistricts] = useState([]);
    const [selectedNeighboor, setSelectedNeighboor] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (selectedCity) {
                    console.log(selectedCity)
                    const response = await axiosInstance.get('/api/users/district?city=' + selectedCity);
                    setDistricts(response.data.response);
                }
            } catch (error) {
                console.error('Veri alınırken hata oluştu:', error);
            }
        };

        fetchData();
    }, [selectedCity]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (selectedDistricts) {
                    const response = await axiosInstance.get('/api/users/county?district=' + selectedDistricts);
                    setNeighboor(response.data.response);
                }
            } catch (error) {
                console.error('Veri alınırken hata oluştu:', error);
            }
        };

        fetchData();
    }, [selectedDistricts]);

    const handleCityChange = (e) => {
        setSelectedCity(e.target.value);
    };

    const handleDistrictChange = (e) => {
        setSelectedDistricts(e.target.value);
    };

    const handleNeigChange = (e) => {
        setSelectedNeighboor(e.target.value);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get('/api/users/cities'); // Backend'den veriyi al
                setCities(response.data.response);
            } catch (error) {
                console.error('Veri alınırken hata oluştu:', error);
            }
        };

        fetchData();
    }, []);

    const handleSuccessNotification = () => {
        success({
            title: 'Başarılı!',
            text: 'Kayıt Başarılı!',
        });
    };

    const handleErrorNotification = (errormessage) => {
        error({
            title: 'Hata!',
            text: errormessage,
        });
    };

    const selectTabs = useCallback((id) => {
        document.querySelectorAll('.the-tabs').forEach(function (element) {
            element.classList.add('d-none');

            if(parseInt(element.getAttribute('data-tabs')) === id) {
                element.classList.remove('d-none');
                document.querySelectorAll('.tabs-btn').forEach(function (el) {
                    el.classList.remove('active');
                })
                document.querySelector(".tabs-btn[data-tabs='"+id+"']").classList.add('active');
            }
        })
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {

            const formData = new FormData();
            // formData.append('productCode', productCode);
            // formData.append('productTitle', productTitle);
            // formData.append('measureUnit', measureUnit);
            // formData.append('productGroup', productGroup);
            // formData.append('manufacturerNo', 1);
            // formData.append('manufacturerTitle', 2);
            // formData.append('barcode', barcode);
            // formData.append('kdv', kdv);
            // formData.append('brand', brand);
            // formData.append('model', model);
            // formData.append('partyTracking', partyTracking ? 1 : 0);
            // formData.append('serialNoTracking', serialNoTracking ? 1 : 0);
            // formData.append('purchasePrice', purchasePrice);
            // formData.append('salesPrice', salesPrice);
            // formData.append('purchasePriceCurrency', purchasePriceCurrency == 'TRY' ? 1 : 2);
            // formData.append('salesPriceCurrency', salesPriceCurrency == 'TRY' ? 1 : 2);
            // formData.append('comment', comment);

            const response = await axiosInstance.post('/api/product/create', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            handleSuccessNotification();
        } catch (error) {
            handleErrorNotification(error.message);
        }
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-8">
                        <div className="col-12">
                            <div className="app-pagination-category-title d-flex align-items-center">
                                <h4 className="pl-1 mb-3">Tedarikçi Oluştur</h4>
                                <button type="submit" className="btn btn-sm btn-primary ml-2"
                                        onClick={handleSubmit}>Kaydet
                                </button>
                            </div>
                        </div>

                        <div className="smart-card p-2">
                            <form ref={formRef} onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <div className="tabs-area">
                                            <button type="button" className="tabs-btn active" data-tabs="0"
                                                    onClick={() => selectTabs(0)}>Tedarikçi Genel Bilgiler
                                            </button>
                                            <button type="button" className="tabs-btn" data-tabs="1"
                                                    onClick={() => selectTabs(1)}>Adres Bilgileri
                                            </button>
                                            <button type="button" className="tabs-btn" data-tabs="2"
                                                    onClick={() => selectTabs(2)}>Ödeme/Muhasebe Bilgileri
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-12 the-tabs p-0" data-tabs="0">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Satıcı Hitap Türü</label>
                                                    <select>
                                                        <option>Seçiniz</option>
                                                        <option>Şahıs</option>
                                                        <option>Firma</option>
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label>Satıcı Ünvanı</label>
                                                    <input type="text" placeholder="Ünvan"/>
                                                    <input type="text" className="form-control mt-2"
                                                           placeholder="Ünvan"/>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-md-4">
                                                        <label>Satıcı Vergi NO / TC</label>
                                                        <input type="text" placeholder="****"/>
                                                    </div>
                                                    <div className="form-group col-md-4">
                                                        <label>Satıcı Vergi Dairesi</label>
                                                        <input type="text" placeholder="****"/>
                                                    </div>
                                                    <div className="form-group col-md-4">
                                                        <label htmlFor="phone">Telefon</label>
                                                        <IntlTelInput
                                                            initialValue={phone}
                                                            onChangeNumber={setPhone}
                                                            initOptions={{
                                                                initialCountry: "tr",
                                                                utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@24.5.0/build/js/utils.js"
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row p-0">
                                                    <div className="form-group col-md-6">
                                                        <label>Satıcı Mail Adresi</label>
                                                        <input type="text" placeholder="****@***"/>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label>Satıcı Sektör</label>
                                                        <select>
                                                            <option>Seçiniz</option>
                                                            <option>Şahıs</option>
                                                            <option>Firma</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 the-tabs p-0 d-none" data-tabs="1">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="surname">Ülke</label>
                                                    <input
                                                        type="text"
                                                        id="surname"
                                                        value="Türkiye"
                                                        placeholder="Soyadınız"
                                                        readOnly
                                                        disabled
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="city">Satıcı İl</label>
                                                    <select id="city" value={selectedCity} onChange={handleCityChange}>
                                                        <option value="">İl Seçiniz</option>
                                                        {cities.map((city) => (
                                                            <option key={city.id} value={city.id}>
                                                                {city.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="district">Satıcı İlçe</label>
                                                    <select id="district" value={selectedDistricts}
                                                            onChange={handleDistrictChange}>
                                                        <option value="">İlçe Seçiniz</option>
                                                        {districts.map((district) => (
                                                            <option key={district.id} value={district.id}>
                                                                {district.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="county">Satıcı Semt</label>
                                                    <select id="county" value={selectedNeighboor}
                                                            onChange={handleNeigChange}>
                                                        <option value="">Semt Seçiniz</option>
                                                        {neighboor.map((neig) => (
                                                            <option key={neig.id} value={neig.id}>
                                                                {neig.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="adress">Satıcı Adres</label>
                                                    <textarea
                                                        rows={2}
                                                        id="adress"
                                                        value={address}
                                                        onChange={(e) => setAddress(e.target.value)}
                                                        required
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 the-tabs p-0 d-none" data-tabs="2">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="row p-0">
                                                    <div className="form-group col-md-4">
                                                        <label>Para Birimi</label>
                                                        <select>
                                                            <option>Seçiniz</option>
                                                            <option>TRY</option>
                                                            <option>EUR</option>
                                                            <option>USD</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-md-4">
                                                        <label>Ödeme Vadesi</label>
                                                        <select>
                                                            <option>Seçiniz</option>
                                                            <option>3</option>
                                                            <option>5</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-md-4">
                                                        <label>Bloke</label>
                                                        <div className="d-flex align-items-center mt-3">
                                                            <input type="checkbox" className="w-25"/>
                                                            <span>Satıcıyı Bloke Et</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row p-0">
                                                    <div className="form-group col-md-8">
                                                        <label>IBAN Bilgileri</label>
                                                        <input type="text" placeholder="*********"/>
                                                    </div>
                                                    <div className="form-group col-md-4">
                                                        <label>Güvenilir Satıcı</label>
                                                        <select>
                                                            <option>Seçiniz</option>
                                                            <option>Evet</option>
                                                            <option>Hayır</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 form-group d-grid">
                                        <label>Genel Not</label>
                                        <textarea
                                            rows={4}
                                            className="product-note"
                                            value={comment}
                                            onChange={(e) => setComment(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="col-12">
                            <div className="app-pagination-category-title d-flex align-items-center">
                                <button type="submit" className="btn btn-sm btn-primary mt-3 mb-1">Etiketleri Yazdır
                                </button>
                                <button type="submit" className="btn btn-sm btn-secondary ml-2 mt-3 mb-1">Takip Et
                                </button>
                                <button type="submit" className="btn btn-sm btn-secondary ml-2 mt-3 mb-1">İşlemler
                                </button>
                            </div>
                        </div>
                        <div className="d-none">

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateVendor;