import '../css/UserRequests.css';
import React, { useMemo, useState, useEffect } from "react";
import { useTable, usePagination } from "react-table";
import axiosInstance from "../api/axiosConfig";
import dayjs from 'dayjs';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from '../partials/Loader';
import Modal from '../elements/Modal';
import { success, error } from '@pnotify/core';

const UserRequests = () => {
    const [allData, setAllData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);

    const [companyName, setCompanyName] = useState(false);

    const handleSuccessNotification = () => {
        success({
            title: 'Başarılı!',
            text: 'İşlem başarıyla gerçekleştirildi!',
        });
    };

    const handleErrorNotification = (errormessage) => {
        error({
            title: 'Hata!',
            text: errormessage,
        });
    };

    const fetchData = async (page = 1, limit = 10) => {
        try {
            const response = await axiosInstance.get('/api/users/get-all', {
                params: { page, limit }
            }).finally(function () {
                setLoading(false);
            });
            setAllData(response.data.response);
            setTotalCount(response.data.total);
        } catch (error) {
            handleErrorNotification(error.message);
        }
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        setLoading(true);
        fetchData(newPage, pageSize); // Verileri güncelle
    };

    useEffect(() => {
        fetchData(currentPage, pageSize); // İlk yükleme
    }, [currentPage, pageSize]);

    const handleButtonClick = (user) => {
        setCompanyName(user.company_name);
        setShowModal(!showModal);
    };

    const data = useMemo(
        () => allData.map(user => ({
            ...user,
            created_at: dayjs(user.created_at).format('DD.MM.YYYY HH:mm:ss') // Tarih formatlama
        })), [allData]
    );

    const columns = useMemo(
        () => [
            {
                Header: "Firma Adı",
                accessor: "company_name"
            },
            {
                Header: "E-Posta",
                accessor: "email"
            },
            {
                Header: "TC/Vergi No",
                accessor: "tax_or_tc"
            },
            {
                Header: "Telefon",
                accessor: "phone"
            },
            {
                Header: "Tarih",
                accessor: "created_at"
            },
            {
                Header: "Statü",
                Cell: ({ row }) => (
                    <span className="user-passive-span">Pasif</span>
                )
            },
            {
                Header: "İşlem",
                Cell: ({ row }) => (
                    <FontAwesomeIcon onClick={() => handleButtonClick(row.original)} icon={faEye} />
                )
            }
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state,
        gotoPage,
        canPreviousPage,
        canNextPage,
        pageCount,
        nextPage,
        previousPage,
        pageOptions
    } = useTable(
        { columns, data },
        usePagination // Pagination kullanımı
    );

    return (
        <>
            {loading ?
                (<div><Loader/></div>) :
                (<div className="dashboard">
                    <table className="table table-bordered bg-white" {...getTableProps()}
                           style={{border: "solid 1px black"}}>
                        <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()}>
                                        {column.render("Header")}
                                    </th>
                                ))}
                            </tr>
                        ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                        {page.map(row => { // rows yerine page kullanıyoruz
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} key={row.original.id}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps()}>
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>

                    <Modal show={showModal} onClose={handleButtonClick}>
                        <h2>{companyName}</h2>
                        <p>Bu, modal'ın içeriği. İstediğiniz her şeyi buraya koyabilirsiniz!</p>
                    </Modal>

                    <div className="pagination">
                        <button onClick={() => handlePageChange(1)} disabled={currentPage === 1}>
                            {'<<'}
                        </button>
                        <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                            {'<'}
                        </button>
                        <span>
                    Sayfa{' '}
                            <strong>
                        {currentPage} / {Math.ceil(totalCount / pageSize)}
                    </strong>{' '}
                </span>
                        <button onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === Math.ceil(totalCount / pageSize)}>
                            {'>'}
                        </button>
                        <button onClick={() => handlePageChange(Math.ceil(totalCount / pageSize))}
                                disabled={currentPage === Math.ceil(totalCount / pageSize)}>
                            {'>>'}
                        </button>

                        <select
                            value={pageSize}
                            className="d-none"
                            onChange={e => {
                                setPageSize(Number(e.target.value));
                            }}
                        >
                            {[5, 10, 20, 50].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            )
            }
        </>
    );
};

export default UserRequests;