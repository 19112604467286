import React, { useState } from 'react';
import axiosInstance from '../api/axiosConfig';
import '../css/login.css';
import { useNavigate, Link } from "react-router-dom";
import {error, success} from "@pnotify/core";

const Login = () => {
    const navigate = useNavigate();
    const [mail, setMail] = useState('');
    const [password, setPassword] = useState('');

    const handleSuccessNotification = () => {
        success({
            title: 'Başarılı!',
            text: 'Giriş Başarılı. Yönlendiriliyorsunuz.!',
        });
    };

    const handleErrorNotification = (errormessage) => {
        error({
            title: 'Hata!',
            text: errormessage,
        });
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.post('/api/users/login', { mail, password });
            localStorage.setItem('token', response.data.token);

            handleSuccessNotification();

            setTimeout(() => {
                navigate('/');
                window.location.reload();
            }, 1000);
        } catch (error) {
            handleErrorNotification('Lütfen Bilgilerinizi Kontrol Ediniz.');
        }
    };

    return (
        <div className="login-container">
            <div className="login-form">
                <h2>
                    <svg width="95" height="50" xmlns="http://www.w3.org/2000/svg">
                        <text x="10" y="30" className="text">Akıllı</text>
                        <text x="65" y="30" className="text highlight">+</text>
                    </svg>
                </h2>
                <form onSubmit={handleLogin}>
                    <div className="form-group">
                        <label htmlFor="mail">E-Posta</label>
                        <input
                            type="text"
                            id="username"
                            value={mail}
                            onChange={(e) => setMail(e.target.value)}
                            placeholder="Kullanıcı Adı"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Şifre</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Şifre"
                            required
                        />
                    </div>
                    <button type="submit" className="login-button">Giriş Yap</button>
                </form>
                <div className="register-link">
                    <p>Bizimle Çalışmak İster Misiniz? <Link to="/register">Üyelik Başvurusu Yap</Link></p>
                </div>
            </div>
        </div>
    );
};

export default Login;