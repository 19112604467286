import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import '../css/Headernav.css';
import LogoutButton from '../elements/LogoutButton';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFile, faGear, faHome, faPen, faShop, faUser} from "@fortawesome/free-solid-svg-icons";
import {jwtDecode} from "jwt-decode";
const Headernav = () => {
    const location = useLocation();
    const isActive = (path) => location.pathname.split("/")[1] === path;

    const [user, setUser] = useState({
        name: '',
        role: ''
    });

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decoded = jwtDecode(token);
            console.log(decoded)
            setUser({
                name: decoded.company_name,
                role: decoded.role_title
            });
        }
    }, []);

    return (
        <div className="headernav">
            <div className="smart-header">
                <h2 className="headernavLogo">
                    <svg width="95" height="50" xmlns="http://www.w3.org/2000/svg">
                        <text x="10" y="30" className="text">Akıllı</text>
                        <text x="65" y="30" className="text highlight">+</text>
                    </svg>
                    <span className="headernavSpan">Tedarik Yönetimi</span>
                </h2>
                <ul>
                    <li><LogoutButton/></li>
                </ul>
            </div>
            <div className="smart-sidebar">
                <ul className="headerUL">
                    <li className={isActive('') ? 'active' : ''}><Link to="/"> <FontAwesomeIcon
                        icon={faHome}/> &nbsp; Ana Sayfa</Link></li>
                    <li className={isActive('users-management') ? 'active' : ''}><Link
                        to="/users-management"><FontAwesomeIcon
                        icon={faPen}/> &nbsp; Kullanıcı Yönetimi</Link></li>
                    <li className={isActive('data-management') ? 'active' : ''}><Link
                        to="/data-management"><FontAwesomeIcon icon={faUser}/> &nbsp; Ana Veri Yönetimi</Link></li>

                    <li className={isActive('purchase-management') ? 'active' : ''}><Link
                        to="/purchase-management"><FontAwesomeIcon icon={faShop}/> &nbsp; Satınalma İşlemleri</Link></li>
                    <li><Link to="/"><FontAwesomeIcon icon={faGear}/> &nbsp; Ayarlar</Link></li>
                    <li><Link to="/"><FontAwesomeIcon icon={faFile}/> &nbsp; Raporlar</Link></li>
                </ul>
            </div>
        </div>
    );
};

export default Headernav;