import React, {useEffect, useState} from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Headernav from './partials/Headernav';
import Dashboard from './components/Dashboard';
import Users from './components/Users';
import Login from './components/Login';
import Register from './components/Register';
import MasterDataManagement from './components/MasterDataManagement';
import PurchaseManagement from './components/PurchaseManagement';
import UserManagement from './components/UserManagement';
import CreateVendor from './components/CreateVendor';
import CreateProduct from './components/CreateProduct';
import EditProduct from './components/EditProduct';
import './css/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import UserRequests from "./components/UserRequests";
import GlobalLoadingBar from "./elements/progressbar";
import Footer from "./partials/Footer";
import Page404 from "../src/ErrorPages/404";
import '@pnotify/core/dist/PNotify.css';
import './css/Pnofitycustom.css';
import ProductList from "./components/ProductList";
import axiosInstance from "./api/axiosConfig";
import Loader from "./partials/Loader";

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem('token');

    useEffect(() => {
        const checkToken = async () => {
            if (!token) {
                setIsAuthenticated(false);
                setLoading(false);
                return;
            }

            try {
                const response = await axiosInstance.get('/api/users/check', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (response.status === 200) {
                    setIsAuthenticated(true);
                }
            } catch (error) {
                console.error('Token kontrol edilirken hata oluştu:', error);
                setIsAuthenticated(false);
            } finally {
                setLoading(false);
            }
        };

        setTimeout(() => checkToken(), 1000);
    }, [token]);

    const pages = [
        { path: "/", exact: true, component: Dashboard },
        { path: "/#", exact: true, component: Dashboard },

        // { path: "/:slug", component: ListingCourseDetail },
        { path: "/users-management", component: UserManagement },
        { path: "/users", component: Users },
        { path: "/user-requests", component: UserRequests },
        { path: "/data-management", component: MasterDataManagement },
        { path: "/purchase-management", component: PurchaseManagement },
        { path: "/data-management/create-vendor", component: CreateVendor },
        { path: "/data-management/create-product", component: CreateProduct },
        { path: "/data-management/edit-product", component: EditProduct },
        { path: "/data-management/product-list", component: ProductList },
    ];

    const guestPages = [
        { path: "/", exact: true, component: Login },
        { path: "/#", exact: true, component: Login },
        { path: "*", exact: true, component: Login },
        { path: "/register", component: Register },
        { path: "/login", component: Login },
    ];

    return (
        <Router>
            <div className="app">
                {isAuthenticated ? (
                    <>
                        <GlobalLoadingBar/>
                        <Headernav/>
                        <div className="content">
                            <Routes>
                                {pages.map(({component, path}) => {
                                    const Component = component;
                                    return <Route key={path} element={<Component/>} path={path}/>;
                                })}
                                {<Route path="*" element={<Page404/>}/>}
                            </Routes>
                        </div>
                        <Footer/>
                    </>
                ) : (
                    <>
                        {loading ? (
                            (<div><Loader/></div>)
                        ) : (
                            <>
                                <GlobalLoadingBar/>
                                <Routes>
                                    {guestPages.map(({component, path}) => {
                                        const Component = component;
                                        return <Route key={path} element={<Component/>} path={path}/>;
                                    })}
                                    {<Route path="*" element={<Page404/>}/>}
                                </Routes>
                            </>
                        )}

                    </>
                )}
            </div>
        </Router>
    );
};

export default App;
