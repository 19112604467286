import React from 'react';
import '../css/Footer.css'; // CSS dosyasını ekleyin

const Footer = () => {
    return (
        <div className="footer-bottom d-flex justify-content-between align-items-center">
            <p>Copyright © 2024 | Creative By SAPPUR</p>
            <span><span style={{color: `#9E9494`}}>Versiyon:</span> Beta v1.0.0</span>
        </div>
    );
};

export default Footer;