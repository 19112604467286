import React from 'react';
import '../css/Loader.css'; // CSS dosyasını ekleyin

const Loader = () => {
    return (
        <div className="loader">
            <h2 className="animationLogo">
                <svg width="95" height="50" xmlns="http://www.w3.org/2000/svg">
                    <text x="10" y="30" className="text">Akıllı</text>
                    <text x="65" y="30" className="text highlight">+</text>
                </svg>
            </h2>
        </div>
    );
};

export default Loader;