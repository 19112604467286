import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import '../css/Logoutbutton.css';
import {jwtDecode} from "jwt-decode";

const LogoutButton = () => {
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);

    const [user, setUser] = useState({
        name: '',
        role: ''
    });

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decoded = jwtDecode(token);
            console.log(decoded)
            setUser({
                name: decoded.company_name,
                role: decoded.role_title
            });
        }
    }, []);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const handleLogout = () => {
        localStorage.removeItem('token');

        navigate('/');
        window.location.reload();
    };

    return (
        <div className="menu-icon">
            <FontAwesomeIcon onClick={toggleDropdown} icon={faUser} />

            {isOpen && (
                <ul className="dropdownn-menu">
                    <li>
                        <div className="userProfile">
                            <div className="mt-2 d-flex justify-content-center">
                                <span className="role-user">{user.role || 'Rol'}</span>
                            </div>
                            <div className="d-flex justify-content-center mt-2">
                            <img
                                    src="https://media.istockphoto.com/id/1327592506/vector/default-avatar-photo-placeholder-icon-grey-profile-picture-business-man.jpg?s=612x612&w=0&k=20&c=BpR0FVaEa5F24GIw7K8nMWiiGmbb8qmhfkpXcp1dhQg="
                                    alt="profile_image" width="50" height="50"/>
                            </div>
                            <div className="mt-3 text-center">
                                <span className="name-user">{user.name || 'Kullanıcı Adı'}</span>
                            </div>
                        </div>
                    </li>
                    <li className="mt-2">
                        <button onClick={handleLogout}>Logout</button>
                    </li>
                </ul>
            )}
        </div>
    );
};

export default LogoutButton;