import React, { useState, useEffect } from 'react';
import axiosInstance from '../api/axiosConfig';
import {Link} from "react-router-dom";
import IntlTelInput from 'intl-tel-input/react';
import "intl-tel-input/styles";
import { success, error } from '@pnotify/core';

function Register() {
    const [email, setEmail] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [taxNumber, setTaxNumber] = useState('');
    const [address, setAddress] = useState('');
    const [message, setMessage] = useState('');
    const [cities, setCities] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [neighboor, setNeighboor] = useState([]);
    const [subsTime, setsubsTime] = useState([]);
    const [sector, setSector] = useState('');
    const [phone, setPhone] = useState('');


    const [selectedCity, setSelectedCity] = useState('');
    const [selectedSubsTime, setSelectedSubsTime] = useState([]);
    const [selectedDistricts, setSelectedDistricts] = useState([]);
    const [selectedNeighboor, setSelectedNeighboor] = useState([]);

    const handleSuccessNotification = () => {
        success({
            title: 'Başarılı!',
            text: 'Talep başarıyla alındı!',
        });
    };

    const handleErrorNotification = (errormessage) => {
        error({
            title: 'Hata!',
            text: errormessage,
        });
    };

    const handleRegister = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.post('/api/users/register',
                {
                    email,
                    taxNumber,
                    companyName,
                    address,
                    selectedCity,
                    selectedDistricts,
                    selectedNeighboor,
                    selectedSubsTime,
                    sector,
                    phone
                }
            );

            handleSuccessNotification();
            window.location.href = '/';
        } catch (error) {
            handleErrorNotification(error.message);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get('/api/users/cities'); // Backend'den veriyi al
                setCities(response.data.response);
            } catch (error) {
                console.error('Veri alınırken hata oluştu:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            let subsTime = [
                {id: 0, period: '1 Ay'},
                {id: 0, period: '3 Ay'},
                {id: 0, period: '6 Ay'},
                {id: 0, period: '12 Ay'},
                {id: 0, period: '24 Ay'},
            ];

            setsubsTime(subsTime);
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (selectedCity) {
                    console.log(selectedCity)
                    const response = await axiosInstance.get('/api/users/district?city=' + selectedCity);
                    setDistricts(response.data.response);
                }
            } catch (error) {
                console.error('Veri alınırken hata oluştu:', error);
            }
        };

        fetchData();
    }, [selectedCity]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (selectedDistricts) {
                    const response = await axiosInstance.get('/api/users/county?district=' + selectedDistricts);
                    setNeighboor(response.data.response);
                }
            } catch (error) {
                console.error('Veri alınırken hata oluştu:', error);
            }
        };

        fetchData();
    }, [selectedDistricts]);

    const handleCityChange = (e) => {
        setSelectedCity(e.target.value);
    };

    const handleSubsTimeChange = (e) => {
        setSelectedSubsTime(e.target.value);
    };

    const handleDistrictChange = (e) => {
        setSelectedDistricts(e.target.value);
    };

    const handleNeigChange = (e) => {
        setSelectedNeighboor(e.target.value);
    };

    return (
        <div className="login-container">
            <div className="login-form">
                <h2>
                    <svg width="95" height="50" xmlns="http://www.w3.org/2000/svg">
                        <text x="10" y="30" className="text">Akıllı</text>
                        <text x="65" y="30" className="text highlight">+</text>
                    </svg>
                </h2>
                <form onSubmit={handleRegister} className="row">
                    <div className="form-group col-12">
                        <label htmlFor="name">Firma Adı / Ünvan</label>
                        <input
                            type="text"
                            id="company_name"
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            placeholder="Firma Adı"
                            required
                        />
                    </div>
                    <div className="form-group col-6">
                        <label htmlFor="surname">Vergi No / TC Kimlik</label>
                        <input
                            type="text"
                            id="tax_number"
                            value={taxNumber}
                            onChange={(e) => setTaxNumber(e.target.value)}
                            placeholder="Vergi / TC Numarası"
                            required
                        />
                    </div>
                    <div className="form-group col-6">
                        <label htmlFor="phone">Telefon</label>
                        <IntlTelInput
                            initialValue={phone}
                            onChangeNumber={setPhone}
                            initOptions={{
                                initialCountry: "tr",
                                utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@24.5.0/build/js/utils.js"
                            }}
                        />
                    </div>
                        <div className="form-group col-12">
                            <label htmlFor="mail">E-Posta</label>
                            <input
                                type="email"
                                id="mail"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="E-Posta"
                                required
                            />
                        </div>
                        <div className="form-group col-6">
                            <label htmlFor="surname">Ülke</label>
                            <input
                                type="text"
                                id="surname"
                                value="Türkiye"
                                placeholder="Soyadınız"
                                readOnly
                                disabled
                            />
                        </div>
                        <div className="form-group col-6">
                            <label htmlFor="city">İl</label>
                            <select id="city" value={selectedCity} onChange={handleCityChange}>
                                <option value="">İl Seçiniz</option>
                                {cities.map((city) => (
                                    <option key={city.id} value={city.id}>
                                        {city.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group col-6">
                            <label htmlFor="district">İlçe</label>
                            <select id="district" value={selectedDistricts} onChange={handleDistrictChange}>
                                <option value="">İlçe Seçiniz</option>
                                {districts.map((district) => (
                                    <option key={district.id} value={district.id}>
                                        {district.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group col-6">
                            <label htmlFor="county">Semt</label>
                            <select id="county" value={selectedNeighboor} onChange={handleNeigChange}>
                                <option value="">Semt Seçiniz</option>
                                {neighboor.map((neig) => (
                                    <option key={neig.id} value={neig.id}>
                                        {neig.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="form-group col-12">
                            <label htmlFor="adress">Adres</label>
                            <textarea
                                rows={2}
                                id="adress"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                required
                            ></textarea>
                        </div>
                        <div className="form-group col-8">
                            <label htmlFor="sector">Sektör</label>
                            <input
                                type="text"
                                id="sector"
                                value={sector}
                                onChange={(e) => setSector(e.target.value)}
                                placeholder="Soyadınız"
                                required
                            />
                        </div>
                        <div className="form-group col-4">
                            <label htmlFor="surname">Üyelik Süresi</label>
                            <select id="district" value={selectedSubsTime} onChange={handleSubsTimeChange}>
                                <option value="">Seçiniz</option>
                                {subsTime.map((st) => (
                                    <option key={st.id} value={st.id}>
                                        {st.period}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group col-12 mt-3">
                            <button type="submit" className="login-button">Kayıt Ol</button>
                        </div>
                </form>
                <div className="register-link">
                    <p>Zaten üye misiniz? <Link to="/login">Giriş Yap</Link></p>
                </div>
            </div>
        </div>
);
}

export default Register;