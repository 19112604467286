import '../css/Users.css';
import React, { useMemo, useState, useEffect } from "react";
import { useTable, usePagination } from "react-table";
import axiosInstance from "../api/axiosConfig";
import dayjs from 'dayjs';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Users = () => {
    const [allData, setAllData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get('/api/users/get'); // Backend'den veriyi al
                setAllData(response.data.response);
            } catch (error) {
                console.error('Veri alınırken hata oluştu:', error);
            }
        };

        fetchData();
    }, []);

    const handleButtonClick = (user) => {
        console.log("Butona tıklanan kullanıcı:", user);
    };

    const data = useMemo(
        () => allData.map(user => ({
            ...user,
            created_at: dayjs(user.created_at).format('DD.MM.YYYY HH:mm:ss') // Tarih formatlama
        })), [allData]
    );

    const columns = useMemo(
        () => [
            {
                Header: "Firma Adı",
                accessor: "company_name"
            },
            {
                Header: "E-Posta",
                accessor: "email"
            },
            {
                Header: "TC/Vergi No",
                accessor: "tax_or_tc"
            },
            {
                Header: "Telefon",
                accessor: "phone"
            },
            {
                Header: "Tarih",
                accessor: "created_at"
            },
            {
                Header: "Statü",
                Cell: ({ row }) => (
                    <span>Pasif</span>
                )
            },
            {
                Header: "İşlem",
                Cell: ({ row }) => (
                    <FontAwesomeIcon onClick={() => handleButtonClick(row.original)} icon={faEye} />
                )
            }
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page, // Sayfa verilerini almak için 'rows' yerine 'page' kullanıyoruz
        prepareRow,
        state,
        setPageSize,
        gotoPage,
        canPreviousPage,
        canNextPage,
        pageCount,
        nextPage,
        previousPage,
        pageOptions
    } = useTable(
        { columns, data },
        usePagination // Pagination kullanımı
    );

    return (
        <div className="dashboard">
            <table className="table table-bordered bg-white" {...getTableProps()} style={{ border: "solid 1px black" }}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>
                                {column.render("Header")}
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map(row => { // rows yerine page kullanıyoruz
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()} key={row.original.id}>
                            {row.cells.map(cell => {
                                return (
                                    <td {...cell.getCellProps()}>
                                        {cell.render("Cell")}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
                </tbody>
            </table>

            <div className="pagination">
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                </button>
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                </button>
                <span>
                    Sayfa{' '}
                    <strong>
                        {state.pageIndex + 1} / {pageOptions.length}
                    </strong>{' '}
                </span>
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                </button>
                <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    {'>>'}
                </button>

                <select
                    value={state.pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value));
                    }}
                >
                    {[5, 10, 20, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default Users;