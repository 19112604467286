import axios from 'axios';

// Create an instance of axios with the backend URL as the base
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL, // Base URL from .env
    headers: {
        'Content-Type': 'application/json', // Optional: default headers
    },
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;