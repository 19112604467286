import React, {useEffect, useLayoutEffect, useMemo, useState} from 'react';
import $ from 'jquery';
import moment from 'moment';
import { gsap, Power3 } from 'gsap';
import '../css/Dashboard.css';
import 'moment/locale/tr';
import dayjs from 'dayjs';
import axiosInstance from "../api/axiosConfig";
import {error, success} from "@pnotify/core";
import {faBars, faEdit, faEye, faGear, faPlus, faTimes, faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
moment.locale('tr');
const Dashboard = () => {
    localStorage.setItem('header-nav', "Anasayfa");

    const [allUserRequestData, setAllUserRequestData] = useState([]);
    const [allProductData, setAllProductData] = useState([]);
    const [userRequestLoading, setUserRequestLoading] = useState(true);
    const [productLoading, setProductLoading] = useState(true);

    const handleSuccessNotification = () => {
        success({
            title: 'Başarılı!',
            text: 'İşlem başarıyla gerçekleştirildi!',
        });
    };

    const handleErrorNotification = (errormessage) => {
        error({
            title: 'Hata!',
            text: errormessage,
        });
    };
    const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
        const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
        return {
            x: centerX + radius * Math.cos(angleInRadians),
            y: centerY + radius * Math.sin(angleInRadians),
        };
    };

    const describeArc = (x, y, radius, startAngle, endAngle) => {
        const start = polarToCartesian(x, y, radius, endAngle);
        const end = polarToCartesian(x, y, radius, startAngle);
        const arcSweep = endAngle - startAngle <= 180 ? '0' : '1';
        return [
            'M', start.x, start.y,
            'A', radius, radius, 0, arcSweep, 0, end.x, end.y,
        ].join(' ');
    };

    const setCaptions = () => {
        const now = new Date();
        const hour = now.getHours() % 12;
        const minute = now.getMinutes();
        const hourArc = ((hour * 60 + minute) / (12 * 60)) * 360;
        const minArc = (minute / 60) * 360;

        $(".clockArc.hour").attr("d", describeArc(600, 240, 150, 0, hourArc));
        $(".clockArc.minute").attr("d", describeArc(600, 240, 170, 0, minArc));

        const hourPos = polarToCartesian(600, 240, 150, hourArc);
        const minPos = polarToCartesian(600, 240, 170, minArc);

        $(".clockDot.hour").attr({ cx: hourPos.x, cy: hourPos.y });
        $(".clockDot.minute").attr({ cx: minPos.x, cy: minPos.y });

        $("#time").text(moment().format("H:mm"));
        $("#day").text(moment().format("dddd"));
        $("#date").text(moment().format("D MMMM"));
    };

    useLayoutEffect(() => {
        const initializeClock = () => {
            setCaptions();
            setInterval(setCaptions, 10 * 1000);

            gsap.from(".clockArc", {
                drawSVG: 0,
                ease: Power3.easeOut,
                duration: 0.5,
                stagger: 0.3,
            });

            gsap.from("#time", { y: 350, opacity: 0, ease: Power3.easeOut, duration: 1, delay: 0.5 });
            gsap.from(".dayText", { y: 310, opacity: 0, ease: Power3.easeOut, duration: 1, delay: 1.0 });
            gsap.from(".dateText", { y: 350, opacity: 0, ease: Power3.easeOut, duration: 1, delay: 1.5 });
        };

        // Bileşen yüklendikten sonra animasyonu başlatmak için setTimeout kullanıyoruz
        const timeoutId = setTimeout(initializeClock, 100);

        return () => clearTimeout(timeoutId);
    }, []);

    const fetchData = async (page = 1, limit = 10) => {
        try {
            const response = await axiosInstance.get('/api/users/get-all', {
                params: { page, limit }
            }).finally(function () {
                setUserRequestLoading(false);
            });
            setAllUserRequestData(response.data.response);
        } catch (error) {
            handleErrorNotification(error.message);
        }
    };

    const product_data = useMemo(
        () => allProductData.map(user => ({
            ...user,
            created_at: dayjs(user.created_at).format('DD.MM.YYYY HH:mm:ss') // Tarih formatlama
        })), [allProductData]
    );

    useEffect(() => {
        fetchData(1, 5);
    }, []);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axiosInstance.get('/api/product/get');
                const products = response.data.response;
                const latestProducts = products
                    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) // Tarihe göre azalan sırada sıralama
                    .slice(0, 4); // İlk 5 ürünü seç

                setAllProductData(latestProducts);
            } catch (error) {
                console.error('Veri alınırken hata oluştu:', error);
            }
        };

        Promise.all([fetchProducts()]).then(() => setProductLoading(false));
    }, []);

    return (
        <div className="dashboard">
            <div className="container-fluid p-0 m-0">
                <div className="row">
                    <div className="col-md-8 p-0">
                        <div className="app-pagination-category-title d-block mb-4">
                            <h4 style={{
                                fontSize: "17px",
                                paddingLeft: "30px",
                                marginTop: "20px",
                                borderLeft: "3px solid #d67940",
                                marginLeft: "6px"
                            }}>Önizleme</h4>
                        </div>
                        <div className="row">
                            <div className="col-md-2">
                                <div className="home-counter">
                                    <div>25</div>
                                    <p>Yeni Tedarikçi</p>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="home-counter">
                                    <div>15</div>
                                    <p>Yeni Abone</p>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="home-counter">
                                    <div>3</div>
                                    <p>Yeni Ürün/Malzeme</p>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="home-counter">
                                    <div>6</div>
                                    <p>Yeni Talep</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="app-pagination-category-title d-block mb-4">
                                    <h4 style={{
                                        fontSize: "17px",
                                        paddingLeft: "30px",
                                        marginTop: "20px",
                                        borderLeft: "3px solid #d67940",
                                        marginLeft: "2px"
                                    }}>Kısayollar</h4>
                                </div>
                                <div className="app-pagination p-0">
                                    <div className="app-item">
                                        <Link to="/data-management/product-list">
                                            <span>Ürün Malzeme Listesi</span>
                                            <FontAwesomeIcon icon={faBars}/>
                                        </Link>
                                    </div>
                                    <div className="app-item">
                                        <Link to="/data-management/create-product">
                                            <span>Ürün Malzeme Oluştur</span>
                                            <FontAwesomeIcon icon={faGear}/>
                                        </Link>
                                    </div>
                                    <div className="app-item">
                                        <Link to="/data-management/edit-product">
                                            <span>Ürün Malzeme Düzenle</span>
                                            <FontAwesomeIcon icon={faEdit}/>
                                        </Link>
                                    </div>
                                    <div className="app-item">
                                        <Link to="/data-management/create-vendor">
                                            <span>Tedarikçi Oluştur</span>
                                            <FontAwesomeIcon icon={faUser}/>
                                        </Link>
                                    </div>
                                    <div className="app-item" style={{background: "#2523233b"}}>
                                        <Link to="/">
                                            <span style={{color: "#000"}}>Kısayol Ekle</span>
                                            <FontAwesomeIcon icon={faPlus}/>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="app-pagination-category-title d-block mb-4">
                            <h4 style={{
                                fontSize: "17px",
                                paddingLeft: "0px",
                                paddingBottom: "10px",
                                marginTop: "20px",
                                borderBottom: "3px solid #d67940"
                            }}>Son Abonelik Talepleri</h4>
                        </div>
                        <div className="user_area_home">
                            <div>
                                {allUserRequestData.map(row => { // rows yerine page kullanıyoruz
                                    return (
                                        <span className="home-spec-span" key={1}>
                                            <span>
                                                {row.id}
                                            </span>
                                            <span>
                                                {row.company_name}
                                            </span>
                                            <span>
                                                {row.email}
                                            </span>
                                            <span>
                                                <FontAwesomeIcon icon={faEye}/>
                                            </span>
                                        </span>
                                    );
                                })}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
